import { createSlice } from '@reduxjs/toolkit'
import { SaleTypesResponse } from '../../API/apiInterfaces'

interface InitialStateProps {
  saleType: {
    saleTypeData: SaleTypesResponse[]
  }
  saleTypesDataError: string | undefined
}

const initialState: InitialStateProps = {
  saleType: {
    saleTypeData: [],
  },
  saleTypesDataError: undefined,
}

const saleTypesSlice = createSlice({
  name: 'saleTypesSlice',
  initialState,
  reducers: {
    getSaleTypesData: (state, { payload }) => {
      state.saleType.saleTypeData = payload
    },
    errorGettingSaleTypesData: (state, { payload }) => {
      state.saleTypesDataError = payload
    },
  },
})

export const { getSaleTypesData, errorGettingSaleTypesData } =
  saleTypesSlice.actions
export default saleTypesSlice.reducer
