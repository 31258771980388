import { useEffect, useState } from 'react'
import { useAccount, useWriteContract } from 'wagmi'

import { paymentWalletSelector } from '../../../Redux/UserState/userSelectors'
import { useAppSelector } from '../../../Redux/hooks'
import { wagmigotchiABI } from '../../../Utils/contractData'
import { selectorPromocode } from '../../purchase-promocode'
import { useUsdtBalance } from '../../../shared/lib/hooks/use-usdt-balance'
import { userData } from '../../../Redux/UserState/userSelectors'
import { shortHandInfo } from '../../../shared/lib/utils'
import { sendPostOrder } from '../api/post-order'
import { sendCancelOrder } from '../api/cancel-order'
import { sendPatchOrderById } from '../api/patch-order-by-id'
import { sendPostOrderSingle } from '../api/post-order-single'
import type { WhiteListByIdResponse } from '../../../API/apiInterfaces'

interface UseMakeOrderParams {
  whitelist: WhiteListByIdResponse
  quantity: number
  price: number
  paymentMethod: 'metamask' | 'whitepay_crypto'
  successCb: () => void
}

interface PriceChangeMeta {
  price: number
  id: number
}

export const useMakeOrder = ({
  whitelist,
  price,
  quantity,
  paymentMethod,
  successCb,
}: UseMakeOrderParams) => {
  const { address, isConnected } = useAccount()
  const { data, writeContract } = useWriteContract()

  const { normalized: usdtBalance } = useUsdtBalance()

  const user = useAppSelector(userData)
  const recipient = useAppSelector(paymentWalletSelector)
  const promocode = useAppSelector(selectorPromocode)

  const [isOrderTriggered, setOrderTriggered] = useState(false)
  const [priceChangeMeta, setPriceChangeMeta] =
    useState<PriceChangeMeta | null>(null)
  const [orderId, setOrderId] = useState(0)
  const [disabled, setDisabled] = useState(false)

  const allowMultiBuy = user.allowMultiBuy && whitelist.project.allowMultiBuy

  const cancelOrder = async (orderId: number) => {
    try {
      sendCancelOrder(orderId)
    } catch (err) {
      console.log(`cancellation error: ${JSON.stringify(err)}`)
    } finally {
      setPriceChangeMeta(null)
    }
  }

  const sendTx = (orderId: number, price: number) =>
    writeContract(
      {
        abi: wagmigotchiABI,
        address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        args: [recipient, price * 1000000],
        functionName: 'transfer',
      },
      {
        onError: () => cancelOrder(orderId),
        onSettled: () => setDisabled(false),
      },
    )

  useEffect(() => {
    const fn = async () => {
      if (orderId && data) {
        await sendPatchOrderById({ id: orderId, externalPaymentId: data! })
        successCb()
        setPriceChangeMeta(null)
      }
    }

    fn()
  }, [data, orderId])

  const postOrder = async () => {
    switch (paymentMethod) {
      case 'metamask': {
        let res = null

        if (allowMultiBuy) {
          res = await sendPostOrder({
            projectSlug: whitelist.project.slug,
            whitelistsCount: quantity,
            paymentMethod: 'metamask',
            promoCode: promocode ?? null,
          })

          if (price !== res.data.price) {
            setPriceChangeMeta({ id: res.data.id, price: res.data.price })
            return
          }
        } else {
          res = await sendPostOrderSingle({
            whitelistId: whitelist.id,
            promoCode: promocode,
            paymentMethod,
          })
        }

        setOrderId(res.data.id)
        sendTx(res.data.id, res.data.price)
      }
    }
  }

  useEffect(() => {
    if (
      isOrderTriggered &&
      isConnected &&
      user.wallet === shortHandInfo('address', address!) &&
      usdtBalance != null
    ) {
      postOrder()
      setOrderTriggered(false)
    }
  }, [
    isOrderTriggered,
    isConnected,
    user.wallet,
    address,
    postOrder,
    usdtBalance,
  ])

  return {
    disabled,
    makeOrder: () => {
      setDisabled(true)
      setOrderTriggered(true)
    },
    priceChangeMeta,
    confirmOrder: () => sendTx(priceChangeMeta!.id, priceChangeMeta!.price),
    cancelOrder: () => cancelOrder(priceChangeMeta!.id),
    allowMultiBuy,
  }
}
