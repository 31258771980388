import axios, { InternalAxiosRequestConfig } from 'axios'
import { SessionStorage } from '../../Utils/SessionStorage'

const url = process.env.REACT_APP_API_HOST
const instance = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const getRefreshToken = async (
  _errConfig: InternalAxiosRequestConfig<any> | undefined,
) => {
  const refreshToken = SessionStorage.getRefreshToken()
  try {
    SessionStorage.revokeSession()
    const rs = await instance.post(
      `${process.env.REACT_APP_API_HOST}auth/v1/token/refresh`,
      {
        refresh_token: refreshToken,
      },
    )
    const { token, refresh_token } = rs.data
    SessionStorage.createSession(token, refresh_token)
    return
  } catch (error) {
    setTimeout(() => {
      window.location.href = '/sign-in'
    }, 1500)
    return Promise.reject(error)
  }
}
