import { call, put, takeLeading } from 'redux-saga/effects'
import { getCategory } from '../../API/apiURL'
import { getCategoryData, errorGettingCategoryData } from './categorySlice'

function* categoryFetch() {
  try {
    const result = yield call(() => getCategory())
    yield put(getCategoryData(result.data))
  } catch (_error) {
    yield put(errorGettingCategoryData('Failed to get categories.'))
  }
}

export function* categorySaga() {
  yield takeLeading('categorySlice/getCategoryData', categoryFetch)
}
