export const routes = {
  home: '/',
  signIn: '/sign-in',
  whiteLists: '/whitelists',
  projects: '/projects',
  project: '/projects/:projectSlug',
  whitelist: '/projects/:projectSlug/:whiteListId',
  support: '/support',
  searchResults: '/search-results',
  freeWhitelists: '/free-whitelists',
  launchpads: '/launchpads',
  research: '/research',
  watchlist: '/watchlist',
  account: '/account/:option?/*',
  privacy: '/privacy',
  terms: '/terms',
  cookies: '/cookies',
  errorPage: '*',
}

export const DynamicRoutes = {
  PROJECT: (projectSlug: string) => `${routes.projects}/${projectSlug}`,
}
