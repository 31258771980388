import { call, put, takeLeading } from 'redux-saga/effects'
import { getLaunchpad } from '../../API/apiURL'
import { getLaunchpadData, errorGettingLaunchpadData } from './launchpadSlice'

function* launchpadFetch() {
  try {
    const result = yield call(() => getLaunchpad())
    yield put(getLaunchpadData(result.data))
  } catch (_error) {
    yield put(errorGettingLaunchpadData('Failed to get launchpads.'))
  }
}

export function* launchpadSaga() {
  yield takeLeading('launchpadSlice/getLaunchpadData', launchpadFetch)
}
