import { useState } from 'react'
import cookiesBarStyles from './cookiesBarStyles.module.scss'
import flagIcon from '../../assets/images/icons/flag.svg'
import closeIcon from '../../assets/images/icons/closeIcon.svg'
import { Link } from 'react-router-dom'
import { routes } from '../../routes'
import { useCookies } from 'react-cookie'

export const CookiesBar = () => {
  const [showBar, setShowBar] = useState(true)
  const [cookies, setCookie] = useCookies(['notification'])

  if (cookies['notification']) {
    return null
  }

  const setNotificationCookie = (value: boolean) => {
    const expiryDate = new Date()
    expiryDate.setFullYear(expiryDate.getFullYear() + 1)

    setCookie('notification', value, { path: '/', expires: expiryDate })
    setShowBar(false)
  }

  return (
    <div
      className={`${cookiesBarStyles.cookiesBar} ${
        !showBar && cookiesBarStyles.hideCookiesBar
      }`}
    >
      <div className={cookiesBarStyles.container}>
        <div className={cookiesBarStyles.cookiesBarContent}>
          <div className={cookiesBarStyles.cookiesBarIconWrapper}>
            <img src={flagIcon} alt="flag icon" width="19px" height="19px" />
          </div>
          <p className={cookiesBarStyles.cookiesBarText}>
            <strong>
              We use third-party cookies in order to personalise your
              experience.&nbsp;
            </strong>
            <span>
              Read our&nbsp;
              <Link to={routes.cookies}>Cookie Policy.</Link>
            </span>
          </p>
        </div>
        <div className={cookiesBarStyles.cookiesBarButtons}>
          <button
            className={cookiesBarStyles.cookiesBarButton}
            onClick={() => {
              setNotificationCookie(false)
            }}
          >
            Decline
          </button>
          <button
            className={cookiesBarStyles.cookiesBarButton}
            onClick={() => {
              setNotificationCookie(true)
            }}
          >
            Allow
          </button>
          <button
            className={cookiesBarStyles.cookiesBarButtonClose}
            onClick={() => {
              setShowBar(false)
            }}
          >
            <img width="12px" height="12px" src={closeIcon} alt="close" />
          </button>
        </div>
      </div>
    </div>
  )
}
