import { createSlice } from '@reduxjs/toolkit'
import { LaunchpadResponse } from '../../API/apiInterfaces'

export {}

interface LaunchpadProps {
  launchpadData: LaunchpadResponse[]
  launchpadDataError: string | undefined
}

const initialState: LaunchpadProps = {
  launchpadData: [],
  launchpadDataError: undefined,
}

const launchpadSlice = createSlice({
  name: 'launchpadSlice',
  initialState,
  reducers: {
    getLaunchpadData: (state, { payload }) => {
      state.launchpadData = payload
    },
    errorGettingLaunchpadData: (state, { payload }) => {
      state.launchpadDataError = payload
    },
  },
})
export const { getLaunchpadData, errorGettingLaunchpadData } =
  launchpadSlice.actions

export default launchpadSlice.reducer
