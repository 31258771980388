import { call, put, takeLeading } from 'redux-saga/effects'
import { getUser, patchUserName, userPaymentWallet } from '../../API/apiURL'
import {
  changeUserName,
  errorGettingPaymentWalletData,
  errorGettingUserData,
  getPaymentWallet,
  getUserData,
} from './userSlice'

function* userDataFetch() {
  try {
    const result = yield call(() => getUser())
    yield put(getUserData(result.data))
  } catch (_error) {
    yield put(errorGettingUserData('Failed to get user data.'))
  }
}
function* changeUserNameFetch({ payload }) {
  try {
    const result = yield patchUserName(payload)
    yield put(changeUserName(result.data))
  } catch (_error) {
    yield put(errorGettingUserData('Failed to change users name.'))
  }
}
function* paymentWalletFetch() {
  try {
    const result = yield userPaymentWallet()
    yield put(getPaymentWallet(result.data))
  } catch (_error) {
    yield put(errorGettingPaymentWalletData('Failed to get payment wallet.'))
  }
}

export function* getUserSaga() {
  yield takeLeading('userSlice/getUserData', userDataFetch)
}
export function* getPaymentWalletSaga() {
  yield takeLeading('userSlice/getPaymentWallet', paymentWalletFetch)
}
export function* changeUserNameSaga() {
  yield takeLeading('userSlice/changeUserName', changeUserNameFetch)
}
