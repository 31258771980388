import { useEffect, useState } from 'react'
import { useDisconnect, useSignMessage, useAccount } from 'wagmi'
import { useWeb3Modal } from '@web3modal/wagmi/react'

import { userData } from '../../../Redux/UserState/userSelectors'
import { authErrorSelector } from '../entity/authorizationSelectors'
import { sendMetamaskNonce } from '../api/metamask-nonce'
import { useAppDispatch, useAppSelector } from '../../../Redux/hooks'
import { loginUserViaMetaMask } from '../entity/authorizationSlice'
import {
  resetAuthError,
  errorSetAuthorized,
} from '../entity/authorizationSlice'
import { shortHandInfo } from '../../../shared/lib/utils'

export const useWalletConnect = () => {
  const dispatch = useAppDispatch()
  const { disconnect } = useDisconnect()
  const { signMessage } = useSignMessage()
  const { isConnected, address } = useAccount()
  const { open } = useWeb3Modal()

  const user = useAppSelector(userData)
  const authError = useAppSelector(authErrorSelector)

  const [connectionTriggered, setConnectionTriggered] = useState(false)

  const handleSignMsgSuccess = (data: any) => {
    const requestParams = {
      wallet: address,
      signature: data,
      onError: disconnect,
    }

    dispatch(loginUserViaMetaMask(requestParams))
  }

  const getNonce = async (walletAddress: string) => {
    const nonceRes = await sendMetamaskNonce(walletAddress)

    signMessage(
      { message: nonceRes.nonce },
      { onSuccess: handleSignMsgSuccess, onError: () => disconnect() },
    )
  }

  const connectWallet = () => {
    setConnectionTriggered(true)
    open()
  }

  useEffect(() => {
    const fn = async () => {
      if (connectionTriggered && isConnected && address) {
        // wallet is not associated with account
        if (!user.wallet) {
          getNonce(address)
        }

        // another wallet is associated with account
        if (user.wallet && shortHandInfo('address', address) !== user.wallet) {
          disconnect()
          dispatch(
            errorSetAuthorized(
              `Account is associated with wallet address: ${user.wallet}`,
            ),
          )
        }

        setConnectionTriggered(false)
      }
    }

    fn()
  }, [user, disconnect, connectionTriggered, isConnected, address])

  useEffect(() => {
    return () => {
      if (authError) {
        dispatch(resetAuthError())
      }
    }
  }, [authError])

  return { connectWallet }
}
