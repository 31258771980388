import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import style from './modalComponent.module.scss'

interface Props {
  children: any
  open: boolean
  handleClose: () => void
  className?: string
}

export const ModalComponent = ({
  children,
  open,
  handleClose,
  className,
}: Props) => {
  return (
    <Modal
      className={style.modalWrapper + ' ' + className}
      open={open}
      closeAfterTransition
      onClose={handleClose}
      sx={{
        '& .MuiModal-backdrop': {
          backdropFilter: 'blur(2px)',
        },
      }}
    >
      <Box className={style.modal}>{children}</Box>
    </Modal>
  )
}
