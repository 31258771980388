import type { FC, InputHTMLAttributes, SyntheticEvent } from 'react'
import { InfoOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'

import inputStyle from './styles.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  max: number
  quantity: number
  onInputChange: (value: number) => void
}

export const QuantityInput: FC<Props> = ({
  max,
  quantity,
  onInputChange,
  ...rest
}) => {
  const handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    onInputChange(Number(e.currentTarget.value))
  }

  return (
    <>
      <Box
        display="inline-flex"
        alignItems="center"
        justifyContent="space-between"
        className={inputStyle.container}
      >
        <button
          type="button"
          onClick={() => onInputChange(quantity - 1)}
          className={inputStyle.button}
          disabled={quantity === 1}
        >
          &minus;
        </button>
        <input
          {...rest}
          type="number"
          value={quantity}
          onChange={handleInputChange}
          className={inputStyle.input}
          min={1}
          max={max}
          disabled={quantity === max}
        />
        <button
          type="button"
          onClick={() => onInputChange(quantity + 1)}
          className={inputStyle.button}
          disabled={quantity === max}
        >
          &#43;
        </button>
      </Box>
      {quantity === max && (
        <div className={inputStyle.maxSelectedMsg}>
          <InfoOutlined fontSize="small" />
          <p>
            The maximum number of items available for your account has been
            selected.
          </p>
        </div>
      )}
    </>
  )
}
