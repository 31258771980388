import { Link } from 'react-router-dom'
import plateStyles from './plateStyles.module.scss'
import { useState } from 'react'
import closeIcon from '../../assets/images/icons/closeIcon.svg'
import { routes } from '../../routes'

export const DearPlate = () => {
  const [showPlate, setShowPlate] = useState(true)
  return (
    <>
      {showPlate && (
        <section className={plateStyles.dearPlate}>
          <p className={plateStyles.text}>
            Dear user, this is an alpha version. We will be happy to receive
            feedback. Write to our&nbsp;
            <Link className={plateStyles.link} to={routes.support}>
              support
            </Link>
            , it will be taken into account in the future =&#41;
          </p>
          <button className={plateStyles.close}>
            <img
              src={closeIcon}
              alt="close"
              onClick={() => {
                setShowPlate(false)
              }}
              width="12px"
              height="12px"
            />
          </button>
        </section>
      )}
    </>
  )
}
