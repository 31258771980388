import basketStyle from './basket.module.scss'

import { type SyntheticEvent, type FC, useState, useEffect } from 'react'
import { useAccount } from 'wagmi'
import clsx from 'clsx'

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'

import { useUsdtBalance } from '../../../../shared/lib/hooks/use-usdt-balance'
import { Button } from '../../../../Components/Button'
import { Tooltip } from '../../../../Components/Tooltip'
import polygonIcon from '../../../../assets/images/icons/polygonIcon.svg'
import usdtIcon from '../../../../assets/images/icons/usdtIcon.svg'
import whitePayIcon from '../../../../assets/images/projectLot/whitePay.png'
import { useMakeOrder } from '../../lib/use-make-order'
import { usePurchasePromocode } from '../../../purchase-promocode'
import { Promocode } from '../../../purchase-promocode'
import { useWalletConnect } from '../../../auth/lib/use-wallet-connect'
import { QuantityInput } from '../../../../Components/FormElements/QuantityInput'
import { sendGetOrderPrice } from '../../api/get-order-price'
import { NotificationModal } from '../../../../Components/Modals/Notification'
import type { WhiteListByIdResponse } from '../../../../API/apiInterfaces'
import { getWhiteListById } from '../../../../API/apiURL'

interface BasketProps {
  whitelist: WhiteListByIdResponse
  paymentMethod: 'metamask' | 'whitepay_crypto'
  onSuccess: () => void
  disabled?: boolean
}

const initialPriceMeta = {
  price: 0,
  discount: 0,
  totalPrice: 0,
}

export const Basket: FC<BasketProps> = ({
  onSuccess,
  paymentMethod,
  whitelist,
  disabled,
}) => {
  const { isConnected } = useAccount()

  const { normalized: usdtBalance } = useUsdtBalance()

  const { promocode, promocodeError, resetPromocodeError, applyPromocode } =
    usePurchasePromocode()

  const { connectWallet } = useWalletConnect()

  const [quantity, setQuantity] = useState(1)
  const [expandRefCode, setExpandRefCode] = useState(false)
  const [priceMeta, setPriceMeta] = useState(initialPriceMeta)

  const {
    disabled: orderBtnDisabled,
    makeOrder,
    priceChangeMeta,
    cancelOrder,
    confirmOrder,
    allowMultiBuy,
  } = useMakeOrder({
    whitelist,
    paymentMethod,
    quantity,
    price: priceMeta.totalPrice,
    successCb: onSuccess,
  })

  useEffect(() => {
    if (whitelist.project.slug) {
      retrieveTotalPrice(quantity)
    }
  }, [whitelist])

  const buyNow = (event: SyntheticEvent) => {
    event.preventDefault()
    if (!isConnected) connectWallet()

    makeOrder()
  }

  const retrieveTotalPrice = async (quantity: number) => {
    try {
      const { data } = await sendGetOrderPrice({
        projectSlug: whitelist.project.slug,
        whitelistsCount: quantity,
        promoCode: promocode,
      })

      setQuantity(quantity)
      setPriceMeta(data)
    } catch (e: any) {
      if (e?.response?.status === 409) {
        const { data: wl } = await getWhiteListById({
          projectSlug: whitelist.project.slug,
          id: whitelist.id,
        })
        setQuantity(wl.project.totalAvailable)
        alert('Available whitelist amount has been changed, please try again')
      }
    }
  }

  const makeOrderBtnDisabled =
    disabled ||
    !isConnected ||
    !whitelist.project.totalAvailable ||
    orderBtnDisabled ||
    Number(usdtBalance) < priceMeta.totalPrice ||
    (paymentMethod !== 'metamask' && priceMeta.totalPrice <= 10)

  const getMakeOrderButtonLabel = () => {
    if (!whitelist.project.totalAvailable) return 'Sold out'

    if (disabled) return 'Locked'

    if (orderBtnDisabled) return 'Awaiting wallet confirmation'

    return `${priceMeta.totalPrice.toFixed(2)} Buy Now`
  }

  return (
    <>
      <div className={basketStyle.basketContent}>
        <div className={basketStyle.topContent}>
          <div className={basketStyle.item}>
            <p className={basketStyle.title}>Whitelist </p>
            <p className={basketStyle.code}>{whitelist.wallet}</p>
          </div>
          <div className={basketStyle.item}>
            <p className={basketStyle.price}>
              {whitelist.price}
              <span>
                <img
                  src={usdtIcon}
                  alt="polygon icon"
                  width="20px"
                  height="18px"
                />
                USDT
              </span>
            </p>
          </div>
        </div>
        <div className={basketStyle.chains}>
          <p>
            <img
              src={polygonIcon}
              alt="polygon icon"
              width="14px"
              height="12px"
            />
            Polygon Network
          </p>
          <div>
            <div className={basketStyle.helpIconWrap}>
              <HelpOutlineOutlinedIcon className={basketStyle.helpIcon} />
              <Tooltip
                title="We use Polygon Network when paying with a USDT token."
                text="Please make sure that the currency is debited and withdrawn in the same network, otherwise the currency withdrawal cannot be successful. There are various network effects: rate, minimum withdrawal amount and transfer time."
                classNamesObj={{
                  customTooltipContainer: basketStyle.customTooltip,
                }}
              />
            </div>
            <p>
              <img
                src={usdtIcon}
                alt="polygon icon"
                width="14px"
                height="12px"
              />
              USDT
            </p>
          </div>
        </div>
        {allowMultiBuy && (
          <div className={basketStyle.quantityContainer}>
            <div className={basketStyle.quantityInputWrapper}>
              <label className={basketStyle.quantityLabel}>Quantity</label>
              <QuantityInput
                quantity={quantity}
                max={whitelist.project.totalAvailable}
                onInputChange={retrieveTotalPrice}
              />
            </div>
          </div>
        )}
        <div
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setExpandRefCode((prev) => !prev)
            }
          }}
          onClick={() => setExpandRefCode((prev) => !prev)}
          className={clsx(
            basketStyle.label,
            expandRefCode && basketStyle.active,
          )}
        >
          <div className={basketStyle.box}>
            <KeyboardArrowDownOutlinedIcon />
          </div>
          <span className={basketStyle.textLabel}>Promocode</span>
        </div>
        {expandRefCode && (
          <Promocode
            onApply={(code: string) =>
              applyPromocode(whitelist.project.slug, code)
            }
            onFocus={resetPromocodeError}
            initialValue={promocode}
            isError={Boolean(promocodeError)}
          />
        )}
        <div className={basketStyle.paymentInfo}>
          <div>
            <div className={basketStyle.itemRow}>
              <p>Wallet balance</p>
              <h5>{usdtBalance ?? 0} USDT</h5>
            </div>
            <div className={basketStyle.itemRow}>
              <p>Payment method</p>
              <h5>{paymentMethod[0].toUpperCase() + paymentMethod.slice(1)}</h5>
            </div>
            {priceMeta.discount > 0 && (
              <div className={basketStyle.itemRow}>
                <p>Discount</p>
                <h5>-${priceMeta.discount.toFixed(2)}</h5>
              </div>
            )}
            <div className={basketStyle.itemRow}>
              <p>Total</p>
              <h5>{priceMeta.totalPrice} USDT</h5>
            </div>
          </div>
        </div>
        <>
          <Button
            className={`${basketStyle.buttonBasket} ${
              makeOrderBtnDisabled && basketStyle.buttonBasketDisabled
            }`}
            callback={buyNow}
            variant="contained"
            size="small"
            disabled={makeOrderBtnDisabled}
          >
            <ShoppingCartOutlinedIcon />
            {getMakeOrderButtonLabel()}
          </Button>
          {paymentMethod === 'whitepay_crypto' && (
            <p className={basketStyle.bottomContent}>
              Powered by <img src={whitePayIcon} alt="whitepay icon" />
            </p>
          )}
        </>
      </div>
      <NotificationModal
        type="warning"
        title="Price has been changed"
        open={Boolean(priceChangeMeta)}
        onClose={cancelOrder}
        hideControls
      >
        <p>
          Total price of purcase has been changed:{' '}
          {priceChangeMeta?.price.toFixed(2)} USDT. Would you like to continue?
        </p>
        <div className={basketStyle.modalControlWrapper}>
          <Button variation="outline" onClick={cancelOrder}>
            Cancel
          </Button>
          <Button variation="secondary" onClick={confirmOrder}>
            Confirm
          </Button>
        </div>
      </NotificationModal>
    </>
  )
}
