import { useAccount, useBalance } from 'wagmi'

const POLYGON_USDT_ADDRESS = '0xc2132d05d31c914a87c6611c10748aeb04b58e8f'

export const useUsdtBalance = () => {
  const { address } = useAccount()
  const { data } = useBalance({
    address: address,
    token: POLYGON_USDT_ADDRESS,
  })

  const normalized = data && Number(data.value) / 10 ** data.decimals

  return { normalized, ...data }
}
