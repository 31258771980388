import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createWeb3Modal, useWeb3ModalTheme } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import type { FC, ReactNode } from 'react'
import { WagmiProvider } from 'wagmi'
import { polygon } from 'wagmi/chains'

import logoHome from '../assets/images/icons/logo-home.svg'

const queryClient = new QueryClient()

const themeVar = {
  '--w3m-logo-image-url': `${logoHome}`,
  '--w3m-background-image-url':
    'https://bogatyr.club/uploads/posts/2021-11/thumbs/1636953084_1-bogatyr-club-p-glubokii-chernii-fon-1.jpg',
  '--w3m-accent-fill-color': '#000000',
  '--w3m-z-index': 1350,
}

const projectId = '9c0d2436cff65899c3437be4f9092130'

const metadata = {
  name: 'WhiteList Zone',
  description: 'WhiteList Zone Web3Modal',
  url: 'https://app.wzone.io', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const wagmiConfig = defaultWagmiConfig({
  chains: [polygon],
  projectId,
  metadata,
})

createWeb3Modal({
  wagmiConfig,
  projectId,
})

interface Web3ProvidersProps {
  children: ReactNode
}

export const Web3Providers: FC<Web3ProvidersProps> = ({ children }) => {
  const { setThemeMode, setThemeVariables } = useWeb3ModalTheme()

  setThemeMode('dark')

  setThemeVariables(themeVar)

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
