import { useAppDispatch, useAppSelector } from '../../../Redux/hooks'

import {
  selectorPromocode,
  selectorPromocodeDiscount,
  selectorPromocodeError,
} from '../entity/promocodeSelectors'
import {
  getPromocodeData,
  resetPromocode,
  resetPromocodeError,
} from '../entity/promocodeSlice'

export const usePurchasePromocode = () => {
  const dispatch = useAppDispatch()
  const promocode = useAppSelector(selectorPromocode)
  const promocodeDiscount = useAppSelector(selectorPromocodeDiscount)
  const promocodeError = useAppSelector(selectorPromocodeError)

  const applyPromocode = (projectSlug: string, code: string) =>
    dispatch(getPromocodeData({ projectSlug, code }))

  const handlePromocodeReset = () => dispatch(resetPromocode())

  const handlePromoocodeResetError = () => dispatch(resetPromocodeError())

  return {
    promocode,
    promocodeDiscount,
    promocodeError,
    applyPromocode,
    resetPromocode: handlePromocodeReset,
    resetPromocodeError: handlePromoocodeResetError,
  }
}
