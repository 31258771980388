import { BASE_URL } from '../../../API/apiURL'

export const sendMetamaskNonce = async (walletAddress: string) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ wallet: walletAddress }),
  }

  const res = await fetch(
    `${BASE_URL}auth/v1/connect/metamask/nonce`,
    requestOptions,
  )

  return res.json()
}
