import { createSlice } from '@reduxjs/toolkit'
import { NetworkResponse } from '../../API/apiInterfaces'

export {}

interface NetworkProps {
  networkData: NetworkResponse[]
  networkDataError: string | undefined
}

const initialState: NetworkProps = {
  networkData: [],
  networkDataError: undefined,
}

const networkSlice = createSlice({
  name: 'networkSlice',
  initialState,
  reducers: {
    getNetworkData: (state, { payload }) => {
      state.networkData = payload
    },
    errorGettingNetworkData: (state, { payload }) => {
      state.networkDataError = payload
    },
  },
})

export const { getNetworkData, errorGettingNetworkData } = networkSlice.actions

export default networkSlice.reducer
