import { call, put, takeLeading } from 'redux-saga/effects'

import { fetchPromocodeData } from '../api/fetchPromocode'
import { getPromocodeData, setPromocodeError } from './promocodeSlice'

function* saga({ payload }) {
  try {
    const { projectSlug, code } = payload

    const result = yield call(() => fetchPromocodeData(projectSlug, code))
    yield put(getPromocodeData(result.data))
  } catch (error) {
    yield put(setPromocodeError('Failed to fetch promocode data.'))
  }
}

export function* promocodeDataSaga() {
  yield takeLeading('promocodeSlice/getPromocodeData', saga)
}
