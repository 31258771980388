import { createSlice } from '@reduxjs/toolkit'
import { FreeWhitelistResponse } from '../../API/apiInterfaces'

interface InitialStateProps {
  freeWhiteList: FreeWhitelistResponse[]
  freeWhiteListCount: number
  freeWhitelistDataError: string | undefined
}

const initialState: InitialStateProps = {
  freeWhiteList: [],
  freeWhiteListCount: 0,
  freeWhitelistDataError: undefined,
}

const freeWhiteListSlice = createSlice({
  name: 'freeWhiteListSlice',
  initialState,
  reducers: {
    getFreeWhiteListData: (state, { payload }) => {
      Array.isArray(payload) && (state.freeWhiteList = payload)
    },
    getFreeWhiteListCountData: (state, { payload }) => {
      payload.count && (state.freeWhiteListCount = payload.count)
    },
    errorGettingFreeWhitelistData: (state, { payload }) => {
      state.freeWhitelistDataError = payload
    },
  },
})

export const {
  getFreeWhiteListData,
  getFreeWhiteListCountData,
  errorGettingFreeWhitelistData,
} = freeWhiteListSlice.actions
export default freeWhiteListSlice.reducer
