import { call, put, takeLeading } from 'redux-saga/effects'
import { getNetwork } from '../../API/apiURL'
import { getNetworkData, errorGettingNetworkData } from './networkSlice'

function* networkFetch() {
  try {
    const result = yield call(() => getNetwork())
    yield put(getNetworkData(result.data))
  } catch (_error) {
    yield put(errorGettingNetworkData('Failed to get networks.'))
  }
}

export function* networkSaga() {
  yield takeLeading('networkSlice/getNetworkData', networkFetch)
}
