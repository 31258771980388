import { createSlice } from '@reduxjs/toolkit'
import type {
  CompletedOrdersResponse,
  OrdersResponse,
  PostOrderResponse,
} from '../../../API/apiInterfaces'

interface InitialStateProps {
  orders: OrdersResponse[]
  order: PostOrderResponse
  wallet: CompletedOrdersResponse
  ordersCount: number
  orderDataError: string | undefined
  ordersDataError: string | undefined
  ordersCountDataError: string | undefined
  walletDataError: string | undefined
}

const initialState: InitialStateProps = {
  orders: [],
  ordersCount: 0,
  order: {
    id: null,
    whitelist: {
      id: '',
      projectSlug: '',
      lockedUntil: '',
    },
    status: '',
    acquiringUrl: '',
  },
  wallet: { seedPhrase: '', wallet: '' },
  orderDataError: undefined,
  ordersDataError: undefined,
  ordersCountDataError: undefined,
  walletDataError: undefined,
}

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    getOrdersData: (state, { payload }) => {
      Array.isArray(payload) && (state.orders = payload)
    },
    getOrdersCountData: (state, { payload }) => {
      payload.count && (state.ordersCount = payload.count)
    },
    postOrderData: (state, { payload }) => {
      payload.id && (state.order = payload)
    },
    getOrderDataById: (state, { payload }) => {
      payload.id && (state.order = payload)
    },
    postOrderRefreshByIdData: (state, { payload }) => {
      payload.id && (state.order = payload)
    },
    getCompletedOrderData: (state, { payload }) => {
      state.wallet = payload.wallet ? payload : initialState.wallet
    },
    errorGettingOrderData: (state, { payload }) => {
      state.orderDataError = payload
    },
    errorGettingOrdersData: (state, { payload }) => {
      state.ordersDataError = payload
    },
    errorGettingOrdersCountData: (state, { payload }) => {
      state.ordersCountDataError = payload
    },
    errorGettingWallet: (state, { payload }) => {
      state.walletDataError = payload
    },
  },
})

export const {
  postOrderData,
  getOrdersCountData,
  getOrdersData,
  getCompletedOrderData,
  getOrderDataById,
  postOrderRefreshByIdData,
  errorGettingOrderData,
  errorGettingOrdersData,
  errorGettingOrdersCountData,
  errorGettingWallet,
} = orderSlice.actions
export default orderSlice.reducer
