import { createSlice } from '@reduxjs/toolkit'

interface InitialStateProps {
  filterValue: {
    limit: number
    offset: number
    sortOrder?: string
    sortBy?: string
    ew?: string
    st?: number[]
    n?: number[]
    l?: number[]
    c?: number[]
    p?: number[]
    sq?: string
    ow?: number
    priceMin?: number
    priceMax?: number
  }
}
const initialState: InitialStateProps = {
  filterValue: {
    sq: undefined,
    p: undefined,
    c: undefined,
    l: undefined,
    n: undefined,
    st: undefined,
    ew: undefined,
    sortBy: undefined,
    sortOrder: undefined,
    priceMin: undefined,
    priceMax: undefined,
    ow: undefined,
    limit: 12,
    offset: 0,
  },
}

const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    addFilterElement: (state, { payload }) => {
      payload === 'allItems'
        ? (state.filterValue = initialState.filterValue)
        : (state.filterValue = { ...state.filterValue, ...payload })
    },
  },
})

export const { addFilterElement } = filterSlice.actions

export default filterSlice.reducer
