import { createSlice } from '@reduxjs/toolkit'
import { CategoryResponse } from '../../API/apiInterfaces'

interface CategoryProps {
  categoryData: CategoryResponse[]
  categoryDataError: string | undefined
}

const initialState: CategoryProps = {
  categoryData: [],
  categoryDataError: undefined,
}

const categorySlice = createSlice({
  name: 'categorySlice',
  initialState,
  reducers: {
    getCategoryData: (state, { payload }) => {
      state.categoryData = payload
    },
    errorGettingCategoryData: (state, { payload }) => {
      state.categoryDataError = payload
    },
  },
})
export const { getCategoryData, errorGettingCategoryData } =
  categorySlice.actions

export default categorySlice.reducer
