import { call, put, takeLeading } from 'redux-saga/effects'

import { signInGoogle, signInMetamask } from '../api/sign-in'
import {
  loginUserViaGoogle,
  loginUserViaMetaMask,
  errorSetAuthorized,
} from './authorizationSlice'

function* getLoginViaGoogle({ payload }) {
  try {
    const result = yield call(() => signInGoogle(payload))
    yield put(loginUserViaGoogle(result.data))
  } catch (_error) {
    const errorMsg =
      _error.response?.data?.message || 'Failed to login via Google.'

    yield put(errorSetAuthorized(errorMsg))
  }
}

function* getLoginViaMetamask({ payload }) {
  const { onError, ...reqParams } = payload

  try {
    const result = yield call(() => signInMetamask(reqParams))
    yield put(loginUserViaMetaMask(result.data))
  } catch (_error) {
    onError?.()
    const errorMsg =
      _error.response?.data?.message || 'Failed to login via Metamask.'

    yield put(errorSetAuthorized(errorMsg))
  }
}

export function* loginUserViaGoogleSaga() {
  yield takeLeading('authorizationSlice/loginUserViaGoogle', getLoginViaGoogle)
}

export function* loginUserViaMetamaskSaga() {
  yield takeLeading(
    'authorizationSlice/loginUserViaMetaMask',
    getLoginViaMetamask,
  )
}
