import axios from 'axios'
import Qs from 'qs'
import { SessionStorage } from '../Utils/SessionStorage'
import { getRefreshToken } from '../services/tokenServices'
import type {
  CategoryResponse,
  CompletedOrdersResponse,
  CountResponse,
  FeaturedProjectsBannersResponse,
  FreeWhitelistRequest,
  FreeWhitelistResponse,
  LaunchpadResponse,
  NetworkResponse,
  OrderPriceResponse,
  OrdersByProjectRequest,
  OrdersByProjectResponse,
  OrdersRequest,
  OrdersResponse,
  OrderPriceRequest,
  PaymentWalletResponse,
  PostOrderRequest,
  PostOrderResponse,
  ProjectsBySlugRequest,
  ProjectsBySlugResponse,
  ProjectsRequest,
  ProjectsResponse,
  SaleTypesResponse,
  SocialNetworkAuthUrlRequest,
  SocialNetworkAuthUrlResponse,
  SocialNetworkOAuthCallbackRequest,
  SubscriberRequest,
  SupportRequest,
  UserNameRequest,
  UserResponse,
  WhiteListByIdRequest,
  WhiteListByIdResponse,
  WhiteListsCountRequest,
  WhiteListsPriceRangeResponse,
  WhiteListsRequest,
  WhiteListsResponse,
} from './apiInterfaces'

export const BASE_URL = process.env.REACT_APP_API_HOST
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

axios.interceptors.request.use((config: any) => ({
  baseURL: `${BASE_URL}`,
  paramsSerializer: (params: any) => Qs.stringify(params, { allowDots: true }),
  ...config,
  headers: {
    ...(!localStorage.getItem('auth.token.access')
      ? {}
      : {
          Authorization: `Bearer ${localStorage.getItem('auth.token.access')}`,
        }),
    ...config.headers,
  },
}))

axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error: any) => {
    switch (error.response?.status) {
      case 401:
        if (SessionStorage.getRefreshToken()) {
          await getRefreshToken(error.config)
        }
        break
      default:
        break
    }
    return Promise.reject(error)
  },
)

// user //
export const getUser = async () => axios.get<UserResponse>('public/v1/user')
export const patchUserName = async (request: UserNameRequest) =>
  axios.patch<UserResponse>('public/v1/user', { ...request })
export const userPaymentWallet = async () =>
  axios.get<PaymentWalletResponse>('public/v1/user/payment-wallet')
// discord
export const getDiscordAuthUrl = async (request: SocialNetworkAuthUrlRequest) =>
  axios.get<SocialNetworkAuthUrlResponse>('public/v1/user/discord/auth-url', {
    params: request,
  })
export const sendDataFromDiscord = async (
  request: SocialNetworkOAuthCallbackRequest,
) => axios.post('public/v1/user/discord/add-to-guild', { ...request })
// twitter
export const getTwitterAuthUrl = async (request: SocialNetworkAuthUrlRequest) =>
  axios.get<SocialNetworkAuthUrlResponse>('public/v1/user/twitter/auth-url', {
    params: request,
  })
export const sendDataFromTwitter = async (
  request: SocialNetworkOAuthCallbackRequest,
) => axios.post('public/v1/user/twitter/follow', { ...request })
// saleTypes
export const getSaleTypes = async () =>
  axios.get<SaleTypesResponse[]>('public/v1/sale-types')
// FreeWhitelist
export const getFreeWhitelist = async (request: FreeWhitelistRequest) =>
  axios.get<FreeWhitelistResponse[]>('public/v1/free-whitelists', {
    params: request,
  })
export const getFreeWhitelistCount = async () =>
  axios.get('public/v1/free-whitelists-count')
// Network
export const getNetwork = async () =>
  axios.get<NetworkResponse[]>('public/v1/networks')
// launchpad
export const getLaunchpad = async () =>
  axios.get<LaunchpadResponse[]>('public/v1/launchpads')
// Category
export const getCategory = async () =>
  axios.get<CategoryResponse[]>('public/v1/categories')
//  WhiteList
export const getWhiteListById = async ({
  projectSlug,
  id,
}: WhiteListByIdRequest) =>
  axios.get<WhiteListByIdResponse>(
    `public/v1/projects/${projectSlug}/whitelists/${id}`,
  )
export const getWhiteLists = async (request: WhiteListsRequest) =>
  axios.get<WhiteListsResponse[]>('public/v1/whitelists', { params: request })
export const getWhiteListsCount = async (request: WhiteListsCountRequest) =>
  axios.get<CountResponse>('public/v1/whitelists-count', {
    params: request,
  })
export const getWhiteListsPriceRange = async () =>
  axios.get<WhiteListsPriceRangeResponse>('public/v1/whitelists-price-range')
export const postWhiteListById = async (id: string) =>
  axios.post<WhiteListByIdResponse>(`public/v1/whitelists/${id}/watch`)
export const deleteWhiteListById = async (id: string) =>
  axios.delete<WhiteListByIdResponse>(`public/v1/whitelists/${id}/watch`)
// Project
export const getFeatureProjectsBanners = async () =>
  axios.get<FeaturedProjectsBannersResponse[]>(
    'public/v1/featured-projects-banners',
  )
export const getProjects = async (request: ProjectsRequest) =>
  axios.get<ProjectsResponse[]>('public/v1/projects', { params: request })
export const getProjectsCount = async (request: ProjectsRequest) =>
  axios.get<CountResponse>('public/v1/projects-count', { params: request })
export const getProjectsBySlug = async ({ slug }: ProjectsBySlugRequest) =>
  axios.get<ProjectsBySlugResponse>(`public/v1/projects/${slug}`)
export const postProjectsBySlug = async (slug: string) =>
  axios.post<ProjectsBySlugResponse>(`public/v1/projects/${slug}/watch`)
export const deleteProjectsBySlug = async (slug: string) =>
  axios.delete<ProjectsBySlugResponse>(`public/v1/projects/${slug}/watch`)
// Order
export const postOrders = async (request: PostOrderRequest) =>
  axios.post<OrdersResponse[]>('public/v1/orders', { ...request })
export const postOrdersByProject = async (request: OrdersByProjectRequest) =>
  axios.post<OrdersByProjectResponse>('public/v1/orders', { ...request })
export const getOrderById = async (id: number) =>
  axios.get<PostOrderResponse>(`public/v1/orders/${id}`)
export const getOrderPrice = async (request: OrderPriceRequest) =>
  axios.get<OrderPriceResponse>(`/public/v1/order-price`, { params: request })
export const postOrderCancelById = async (id: number) =>
  axios.post<PostOrderResponse>(`public/v1/orders/${id}/cancel`)
export const postOrderRefreshById = async (id: number) =>
  axios.post<PostOrderResponse>(`public/v1/orders/${id}/refresh`)
export const getCompletedOrder = async (id: number) =>
  axios.get<CompletedOrdersResponse>(`public/v1/orders/${id}/wallet`)
export const getOrders = async (request: OrdersRequest) =>
  axios.get<OrdersResponse[]>('public/v1/orders', { params: request })
export const getOrdersCount = async () =>
  axios.get<CountResponse>('public/v1/orders-count')
// subscriber
export const sendSubscriber = async (request: SubscriberRequest) =>
  axios.post('public/v1/subscribers', { ...request })
// support
export const sendSupportRequest = async (request: SupportRequest) =>
  axios.post('public/v1/support-requests', { ...request })
