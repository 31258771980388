import axios from 'axios'

interface GetOrderPriceParams {
  projectSlug: string
  whitelistsCount: number
  promoCode?: string
}

interface GetOrderPriceResponse {
  price: number
  discount: number
  totalPrice: number
}

export const sendGetOrderPrice = (params: GetOrderPriceParams) => {
  return axios.get<GetOrderPriceResponse>('public/v1/order-price', { params })
}
