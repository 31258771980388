import { call, put, takeLeading } from 'redux-saga/effects'
import {
  getFreeWhiteListData,
  getFreeWhiteListCountData,
  errorGettingFreeWhitelistData,
} from './freeWhiteListSlice'
import { getFreeWhitelist, getFreeWhitelistCount } from '../../API/apiURL'

function* freeWhiteListFetch({ payload }) {
  try {
    const result = yield call(() => getFreeWhitelist(payload))
    yield put(getFreeWhiteListData(result.data))
  } catch (_error) {
    yield put(errorGettingFreeWhitelistData('Failed to get Free WhiteLists.'))
  }
}
function* freeWhiteListCountFetch({ payload }) {
  try {
    const result = yield call(() => getFreeWhitelistCount(payload))
    yield put(getFreeWhiteListCountData(result.data))
  } catch (_error) {
    yield put(
      errorGettingFreeWhitelistData('Failed to get free WhiteLists count.'),
    )
  }
}

export function* freeWhiteListCountSaga() {
  yield takeLeading(
    'freeWhiteListSlice/getFreeWhiteListCountData',
    freeWhiteListCountFetch,
  )
}

export function* freeWhiteListSaga() {
  yield takeLeading(
    'freeWhiteListSlice/getFreeWhiteListData',
    freeWhiteListFetch,
  )
}
