import { routes } from '../../routes'
import { Navigate, useLocation } from 'react-router-dom'
import { SessionStorage } from '../SessionStorage'

interface RequireAuthProps {
  children: JSX.Element
}

export const RequireAuth = ({ children }: RequireAuthProps) => {
  const location = useLocation()
  const authorized = () => SessionStorage.isAuthorized()

  if (!authorized()) {
    return <Navigate to={routes.home} state={{ from: location }} />
  }
  return children
}
