import { call, put, takeLeading, takeLatest } from 'redux-saga/effects'
import {
  getFeatureProjectsBanners,
  getProjects,
  getProjectsCount,
  getProjectsBySlug,
} from '../../API/apiURL'
import {
  getFeaturedProjectBannersData,
  getProjectsDataSuccess,
  getProjectsDataError,
  getProjectsCountData,
  getProjectsBySlugData,
  errorGettingFeaturedProjectsData,
  errorGettingProjectData,
  errorGettingProjectsCountData,
} from './projectSlice'

function* featuredProjectBannersFetch() {
  try {
    const result = yield call(() => getFeatureProjectsBanners())
    yield put(getFeaturedProjectBannersData(result.data))
  } catch (_error) {
    yield put(
      errorGettingFeaturedProjectsData('Failed to get featured projects.'),
    )
  }
}
function* projectsFetch({ payload }) {
  const initFetching = payload.offset === 0

  try {
    const result = yield call(() => getProjects(payload))
    const prepearResult = result.data.sort((a, b) => b.id - a.id)
    yield put(getProjectsDataSuccess({ data: prepearResult, initFetching }))
  } catch (_error) {
    yield put(getProjectsDataError('Failed to get projects.'))
  }
}
function* projectsCountFetch({ payload }) {
  try {
    const result = yield call(() => getProjectsCount(payload))
    yield put(getProjectsCountData(result.data))
  } catch (_error) {
    yield put(errorGettingProjectsCountData('Failed to get projects counter.'))
  }
}
function* projectsBySlugFetch({ payload }) {
  try {
    const result = yield call(() => getProjectsBySlug(payload))
    yield put(getProjectsBySlugData(result.data))
  } catch (_error) {
    yield put(errorGettingProjectData('Failed to get project.'))
  }
}

export function* featuredProjectBannersSaga() {
  yield takeLeading(
    'projectSlice/getFeaturedProjectBannersData',
    featuredProjectBannersFetch,
  )
}
export function* projectsCountSaga() {
  yield takeLeading('projectSlice/getProjectsCountData', projectsCountFetch)
}

export function* projectsSaga() {
  yield takeLatest('projectSlice/getProjectsData', projectsFetch)
}

export function* projectsBySlugSaga() {
  yield takeLeading('projectSlice/getProjectsBySlugData', projectsBySlugFetch)
}
