import clsx from 'clsx'

import errorIcon from '../../../assets/images/icons/alert-circle.svg'
import warningIcon from '../../../assets/images/icons/alert-triangle.svg'
import successIcon from '../../../assets/images/icons/doneIcon.png'
import styles from './notificationStyles.module.scss'
import CloseIcon from '@mui/icons-material/Close'
import { ModalComponent } from '../ModalComponent'
import { Button } from '../../Button'

interface Props {
  title: string
  children: any
  type?: string
  open: boolean
  onClose: () => void
  hideControls?: boolean
  className?: string
}

export const NotificationModal = ({
  title,
  type,
  children,
  onClose,
  open,
  hideControls,
  className,
}: Props) => {
  const icon = () => {
    switch (type) {
      case 'error':
        return errorIcon
      case 'warning':
        return warningIcon
      default:
        return successIcon
    }
  }
  return (
    <ModalComponent handleClose={onClose} open={open}>
      <div className={clsx(styles.notificationWrapper, className)}>
        <div
          className={`${styles.modal} ${
            type === 'error' && styles.errorModal
          } ${type === 'warning' && styles.warningModal}`}
        >
          <div className={styles.topModal}>
            <div className={styles.modalIconWrapper}>
              <img
                className={styles.modalIcon}
                src={icon()}
                alt={`${type} icon`}
                width="24px"
                height="24px"
              />
            </div>
            <Button
              className={styles.buttonClose}
              callback={onClose}
              variant="outlined"
              size="small"
              disabled={false}
              type="button"
              child={<CloseIcon />}
            />
          </div>
          <h1 className={styles.modalTitle}>{title}</h1>
          {children}
          {!hideControls && (
            <button
              type="button"
              className={styles.modalButton}
              onClick={onClose}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </ModalComponent>
  )
}
