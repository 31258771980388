import type { RootState } from '../../../Redux/store'

export const selectorOrders = (state: RootState) => state.order.orders

export const selectorOrdersCount = (state: RootState) => state.order.ordersCount

export const selectorOrder = (state: RootState) => state.order.order

export const selectorWallet = (state: RootState) => state.order.wallet

export const selectorOrderError = (state: RootState) =>
  state.order.orderDataError
