import axios from 'axios'

interface PatchOrderByIdRequest {
  id: number
  externalPaymentId: string
}

export const sendPatchOrderById = async (dto: PatchOrderByIdRequest) =>
  axios.patch(`public/v1/orders/${dto.id}`, {
    externalPaymentId: dto.externalPaymentId,
  })
