import axios from 'axios'

import type {
  SignInGoogleRequest,
  SignInMetaMaskRequest,
  TokenResponse,
} from '../../../API/apiInterfaces'

export const signInGoogle = async (request: SignInGoogleRequest) =>
  axios.post<TokenResponse>(
    'auth/v1/connect/google',
    new URLSearchParams({ ...request }),
  )
export const signInMetamask = async (request: SignInMetaMaskRequest) =>
  axios.post<TokenResponse>(
    'auth/v1/connect/metamask',
    new URLSearchParams({ ...request }),
  )
