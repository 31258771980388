import axios from 'axios'

export interface PostOrderSingleDto {
  whitelistId: string
  paymentMethod: string
  promoCode?: string
}

export const sendPostOrderSingle = (dto: PostOrderSingleDto) => {
  return axios.post('public/v1/orders', dto)
}
