import { createSlice } from '@reduxjs/toolkit'
import { SessionStorage } from '../../../Utils/SessionStorage'

interface InitialStateProps {
  authorized: boolean
  token?: string
  tokenRefresh?: string
  authorizationError?: string
}
const initialState: InitialStateProps = {
  authorized: false,
  token: undefined,
  tokenRefresh: undefined,
  authorizationError: undefined,
}

const authorizationSlice = createSlice({
  name: 'authorizationSlice',
  initialState,
  reducers: {
    setAuthorized: (state, { payload }) => {
      state.authorized = payload
    },
    loginUserViaGoogle: (state, { payload }) => {
      payload.token &&
        SessionStorage.createSession(payload.token, payload.refresh_token)
      state.authorized = Boolean(payload.token)
      state.token = payload.token
      state.tokenRefresh = payload.refresh_token
    },
    loginUserViaMetaMask: (state, { payload }) => {
      payload.token &&
        SessionStorage.createSession(payload.token, payload.refresh_token)
      state.authorized = Boolean(payload.token)
      state.token = payload.token
      state.tokenRefresh = payload.refresh_token
    },
    setTokens: (state, { payload }) => {
      state.token = payload.token
      state.tokenRefresh = payload.refresh_token
    },
    errorSetAuthorized: (state, { payload }) => {
      state.authorizationError = payload
    },
    resetAuthError: (state) => {
      state.authorizationError = initialState.authorizationError
    },
  },
})

export const {
  setAuthorized,
  loginUserViaGoogle,
  loginUserViaMetaMask,
  errorSetAuthorized,
  resetAuthError,
} = authorizationSlice.actions
export default authorizationSlice.reducer
