export class SessionStorage {
  static ACCESS_TOKEN_KEY = 'auth.token.access'
  static REFRESH_TOKEN_KEY = 'auth.token.refresh'
  static USER_AUTH = 'auth.user'

  static createSession(token: string, refreshToken: string) {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, token)
    localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken)
    localStorage.setItem(this.USER_AUTH, 'true')
  }

  static revokeSession() {
    localStorage.removeItem(this.ACCESS_TOKEN_KEY)
    localStorage.removeItem(this.REFRESH_TOKEN_KEY)
    localStorage.removeItem(this.USER_AUTH)
  }

  static getToken() {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY)
  }

  static getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY)
  }

  static isAuthorized() {
    return localStorage.getItem(this.USER_AUTH)
  }
}
