import axios from 'axios'

export interface PostOrderDto {
  projectSlug: string
  whitelistsCount: number
  paymentMethod: 'metamask'
  promoCode: string | null
}

export const sendPostOrder = (dto: PostOrderDto) => {
  return axios.post('public/v1/orders/by-project', dto)
}
