import { call, put, takeLeading } from 'redux-saga/effects'
import { getSaleTypes } from '../../API/apiURL'
import { getSaleTypesData, errorGettingSaleTypesData } from './saleTypeSlice'

function* saleTypesFetch() {
  try {
    const result = yield call(() => getSaleTypes())
    yield put(getSaleTypesData(result.data))
  } catch (_error) {
    yield put(errorGettingSaleTypesData('Failed to get sale types.'))
  }
}

export function* saleTypesSaga() {
  yield takeLeading('saleTypesSlice/getSaleTypesData', saleTypesFetch)
}
